import styled from 'styled-components'
import { breakpoint } from 'src/styles/mixins'

export const Card = styled.div`
  background: ${props => props.background};
  height: 100%;
`

export const ContentArea = styled.div`
  padding: 16px;
  height: 100%;

  ${breakpoint.lg`
    padding: 16px 32px;
  `}
`

export const Heading = styled.h2`
  font-size: ${props => props.theme.fontSize.heading};
  font-family: ${props => props.theme.font.primary};
  color: ${props => props.theme.color.black};
  line-height: 1.4;
  margin: 0;
  font-size: 1.25rem;
`
