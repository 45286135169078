import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'src/components/Layout'
import Section from 'src/components/Section'
import Heading from 'src/components/Heading'
import CategoriesBar from 'src/components/CategoriesBar'
import PostGrid from 'src/components/PostGrid'
import Pagination from 'src/components/Pagination'
import PagePropTypes from 'src/utils/propTypes'
import Seo from 'src/components/Seo'
import { getThemeVar } from 'src/styles/mixins'

export const CategoryTemplate = ({
  title,
  posts,
  categories,
  acf,
  numPages,
  uri,
  parentUri,
}) => (
  <Section
    background={getThemeVar('color.lightblue')}
    hasPadding
    paddingValues={{ xs: '32px 0 80px 0', lg: '72px 0 100px 0' }}
    fullHeight
  >
    <Heading>{title}</Heading>
    <CategoriesBar
      categories={categories}
      defaultItem={{ uri: parentUri, name: acf.defaultCategoryName }}
    />
    <PostGrid posts={posts} readMoreLabel={acf.readMoreLabel} />
    <Pagination numPages={numPages} uri={uri} />
  </Section>
)

CategoryTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
        excerpt: PropTypes.string,
        featuredImage: PropTypes.object, // eslint-disable-line
      }),
    })
  ).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.string,
        uri: PropTypes.string,
      }),
    })
  ).isRequired,
  acf: PropTypes.shape({
    readMoreLabel: PropTypes.string,
    defaultCategoryName: PropTypes.string,
  }).isRequired,
  numPages: PropTypes.number.isRequired,
  uri: PropTypes.string.isRequired,
  parentUri: PropTypes.string.isRequired,
}

const Page = ({ data, pageContext }) => (
  <Layout page={data.wpCategory} pageLocale={data.wpCategory.language.locale}>
    <Seo
      title={data.wpCategory.name}
      locale={data.wpCategory.language.locale}
      description={data.wpCategory.seo.metaDesc}
    />
    <CategoryTemplate
      title={data.wpPage.title}
      posts={data.allWpPost.edges}
      categories={data.allWpCategory.edges}
      acf={data.wpPage.acfBlog}
      numPages={pageContext.numPages}
      uri={data.wpCategory.uri}
      parentUri={data.wpPage.uri}
    />
  </Layout>
)

export const pageQuery = graphql`
  query CategoryById(
    $id: String!
    $parentId: String!
    $locale: String!
    $limit: Int!
    $skip: Int!
  ) {
    wpPage(id: { eq: $parentId }) {
      title
      uri
      acfBlog {
        readMoreLabel
        defaultCategoryName
      }
    }

    wpCategory(id: { eq: $id }) {
      name
      ...categoryTranslationData

      seo {
        metaDesc
      }
    }

    allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { id: { eq: $id } } } }
        language: { locale: { eq: $locale } }
      }
      sort: { order: DESC, fields: date }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          content
          excerpt
          uri
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 550, maxHeight: 385) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    allWpCategory(
      filter: { count: { gte: 1 }, language: { locale: { eq: $locale } } }
    ) {
      edges {
        node {
          name
          uri
        }
      }
    }
  }
`

Page.propTypes = PagePropTypes

export default Page
