import React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { Bar, Item, StyledLink } from './style'

const CategoriesBar = ({ categories, defaultItem, isBlogTemplate }) => {
  const categoriesMapped = categories.map(({ node }) => {
    return (
      <Item key={uuidv4()}>
        <StyledLink to={node.uri} partiallyActive activeClassName="active">
          {node.name}
        </StyledLink>
      </Item>
    )
  })

  return (
    <Bar>
      <Item>
        <StyledLink
          to={defaultItem.uri}
          partiallyActive={isBlogTemplate}
          activeClassName="active"
        >
          {defaultItem.name}
        </StyledLink>
      </Item>
      {categoriesMapped}
    </Bar>
  )
}

CategoriesBar.propTypes = {
  isBlogTemplate: PropTypes.bool,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      uri: PropTypes.string,
    })
  ).isRequired,
  defaultItem: PropTypes.shape({
    uri: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
}

CategoriesBar.defaultProps = {
  isBlogTemplate: false,
}

export default CategoriesBar
