import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { getThemeVar } from 'src/styles/mixins'

import { Card, ContentArea, Heading } from './style'

const MyCard = ({ fluid, heading, children, background }) => (
  <Card background={background}>
    <Img fluid={fluid} />
    <ContentArea>
      <Heading>{heading}</Heading>
      {children}
    </ContentArea>
  </Card>
)

MyCard.defaultProps = {
  background: getThemeVar('color.lightblue'),
}

MyCard.propTypes = {
  background: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  fluid: PropTypes.object.isRequired, // eslint-disable-line
  heading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default MyCard
