import styled from 'styled-components'
import { Link } from 'gatsby'
import { FontStyle } from 'src/styles/typography'
import { darken } from 'polished'

// eslint-disable-next-line
export const PaginationLink = styled(Link)`
  ${FontStyle}
  font-weight: bold;
  text-decoration: none;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin: 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  color: ${props => props.theme.color.black};
  background: ${props => props.theme.color.lightblue};

  &:hover {
    color: ${props => props.theme.color.green};
  }

  &:focus {
    background: ${props => darken(0.1, props.theme.color.lightblue)};
  }

  &.active {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    color: ${props => props.theme.color.white};
    background: ${props => props.theme.color.green};
  }
`
