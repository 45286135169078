import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core/'
import { v4 as uuidv4 } from 'uuid'
import { PaginationLink } from './style'

const Pagination = ({ numPages, uri }) => {
  const createPath = (pageNumber, pageUri) => {
    return pageNumber === 0 ? pageUri : `${pageUri}${pageNumber + 1}`
  }

  const buttonsMapped = Array.from({ length: numPages }).map((_, i) => (
    <PaginationLink
      key={uuidv4()}
      to={createPath(i, uri)}
      activeClassName="active"
    >
      {i + 1}
    </PaginationLink>
  ))

  return (
    <>
      {numPages > 1 && (
        <Box
          display="flex"
          justifyContent="center"
          mt={{ xs: 6, lg: 10 }}
          mb={{ xs: 0, lg: 2 }}
        >
          {buttonsMapped}
        </Box>
      )}
    </>
  )
}

Pagination.propTypes = {
  numPages: PropTypes.number.isRequired,
  uri: PropTypes.string.isRequired,
}

export default Pagination
