import React from 'react'
import PropTypes from 'prop-types'
import Wysiwyg from 'src/components/Wysiwyg'
import MyCard from 'src/components/MyCard'
import { useStaticQuery, graphql } from 'gatsby'
import { Grid, Box } from '@material-ui/core/'
import { getThemeVar } from 'src/styles/mixins'
import { LinkWithArrow } from 'src/styles/typography'
import { v4 as uuidv4 } from 'uuid'

const PostGrid = ({ posts, readMoreLabel, cardBackground }) => {
  const data = useStaticQuery(graphql`
    query placeholderQuery {
      file(relativePath: { eq: "blog_placeholder.png" }) {
        childImageSharp {
          fluid(maxWidth: 550, maxHeight: 385) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const postsMapped = posts.map(thePost => {
    // blog-template passes blog-nodes as prop, post-acf passes blog-objects
    const post = thePost.node ? thePost.node : thePost
    const link = thePost.node ? post.uri : post.link

    return (
      <Grid item xs={12} md={6} lg={4} key={uuidv4()}>
        <MyCard
          background={cardBackground}
          fluid={
            post.featuredImage
              ? post.featuredImage.node.localFile.childImageSharp.fluid
              : data.file.childImageSharp.fluid
          }
          heading={post.title}
        >
          <Wysiwyg>{post.excerpt}</Wysiwyg>

          <Box my={{ xs: 2, lg: 3 }}>
            <LinkWithArrow to={link}>{readMoreLabel}</LinkWithArrow>
          </Box>
        </MyCard>
      </Grid>
    )
  })

  return (
    <Box mt={4}>
      <Grid container spacing={4}>
        {postsMapped}
        {posts.length < 1 && (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" aligItems="center">
              <h2>No Posts found</h2>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

PostGrid.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ).isRequired,
  readMoreLabel: PropTypes.string,
  cardBackground: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
}

PostGrid.defaultProps = {
  readMoreLabel: 'Read More',
  cardBackground: getThemeVar('color.white'),
}

export default PostGrid
