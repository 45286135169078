import styled from 'styled-components'
import { TextBold } from 'src/styles/typography'
import { Link } from 'gatsby'
import { breakpoint } from 'src/styles/mixins'

export const Item = styled(TextBold)`
  margin: 1rem 1rem 0 0;
`

export const Bar = styled.div`
  display: flex;
  margin-bottom: 32px;

  ${breakpoint.lg`
    margin-bottom: 48px;
  `}
`

export const StyledLink = styled(Link)`
  color: ${props => props.theme.color.black};
  font-family: ${props => props.theme.font.primary};
  text-decoration: none;
  display: block;

  &.active {
    color: ${props => props.theme.color.green};

    &:after {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      margin-top: 2px;
      background: ${props => props.theme.color.green};
    }
  }
`
